<template>
    <div>
        <a-dropdown :visible="show" :trigger="['click']" :overlayClassName="classObj.mobile ? 'mobile' : ''"
            class="menus" :key="'dropdownKey' + isShowNotification" :class="classObj"
            :getPopupContainer="getPopupContainer">
            <div class="fold_icon" @click.stop="show = !show" :class="classObj">
                <img v-if="show" src="@/assets/home/levitated_bonus_fold.gif" alt="">
                <img v-else src="@/assets/home/levitated_bonus_unfold.png" alt="">
            </div>
            <a-menu slot="overlay" class="menus_box" :class="classObj">
                <a-menu-item v-for="(item, index) in list" class="menu_info" :key="index" @click="toItem(item)">
                    <div class="img_box">
                        <img :src="item.homeImgUrl" alt="">
                        <img :src="item.txtIcon" class="txt" alt="">
                    </div>
                    <i class="iconfont icon-s-cuowu-guanbi" @click.stop="closeItem(index)"></i>
                    <div class="matterNumber" v-if="item.eventType === 'customerPending' && matterNumber">
                        {{matterNumber}}
                    </div>
                </a-menu-item>
            </a-menu>
        </a-dropdown>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import {
    getEventsHomeShow
} from '@/api/home'
import { getCustomEventInfo } from '@/api/event'
export default {
    data() {
        return {
            show: true,
            list: [],
            RedPacketList: []
        }
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        matterNumber() {
            return this.$store.getters.matterNumber
        },
        ...mapState({
            device: state => state.app.device,
            isShowNotification: state => state.app.isShowNotification
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile',
                openNotification: this.isShowNotification
            }
        },
        sysConfig() {
            return this.$store.getters.sysConfig
        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        closeItem(index) {
            this.list.splice(index, 1)
        },
        getPopupContainer() {
            return document.querySelector('.main-container')
        },
        getInfo() {
            getEventsHomeShow().then((res) => {
                if (res.code) return
                const D = res.result
                this.list = D.eventManageList
                const obj1 = {
                    eventType: 'customerServiceTelegram',
                    homeImgUrl: require('@/assets/share/icon_telegram.png')
                }
                this.list.push(obj1)
                const obj2 = {
                    eventType: 'customerPending',
                    homeImgUrl: require('@/assets/home/icon_task.png'),
                    txtIcon: require('@/assets/home/task_txt.png')
                }
                this.list.push(obj2)
                // this.RedPacketList = D.homeRedPacketTypeVoList || []
                // if (this.RedPacketList.length) this.isShowRedEnvelopePopup = true
            })
        },
        toItem(item) {
            // console.log(item)
            if (item.eventType === 'reliefMoney') {
                this.$store.dispatch('user/setIsShowLossSupportPopup', { id: item.id, show: true })
            }
            if (item.eventType === 'signIn') {
                this.$store.dispatch('user/setIsShowCheckInPopup', { id: item.id, show: true })
            }
            if (item.eventType === 'luckyWheel') {
                this.$store.dispatch('user/setIsShowTurntablePopup', { id: item.id, show: true })
            }
            if (item.eventType === 'custom') {
                getCustomEventInfo({ id: item.id }).then((res) => {
                    if (res.code) return
                    const D = res.result
                    if (D.displayMode === 'BuiltInPage') {
                        const obj = { id: D.id, eventName: D.eventName, createTime: D.createTime, eventContent: D.eventContent }
                        const params = encodeURIComponent(JSON.stringify(obj))
                        const timestamp = new Date().getTime()
                        this.onNavTo({ url: 'PromotionCustom', type: 'post', params: { timestamp, params } })
                    }
                    if (D.displayMode === 'JumpOutside') {
                        console.log(D.appSkipMode)
                        if (D.appSkipMode === 'newWindowOpen') {
                            const url = D.appHref
                            const type = 'href'
                            this.onNavTo({ url, type })
                        }
                        if (D.appSkipMode === 'nowPageOpen') {
                            // const encodedUrl = encodeURIComponent(D.appHref)
                            // const finalUrl = '/pages/webview/deposit_wv?url=' + encodedUrl
                            // this.onNavTo(finalUrl)
                        }
                        if (D.appSkipMode === 'builtInPageOpen') {
                            const url = D.appHref
                            this.onNavToByIsLogin({ url })
                        }
                    }
                })
            }
            if (item.eventType === 'redPacket') {
                this.$store.dispatch('user/setIsShowRedPacketPopup', { id: item.id, show: true })
            }
            if (item.eventType === 'extend') {
                this.onNavToByIsLogin({}).then(() => {
                    this.$store.dispatch('user/setIsShowTreasureChestPopup', { id: item.id, show: true })
                })
            }
            if (item.eventType === 'gameBetting') {
                this.$store.dispatch('user/setIsShowRebatePopup', { id: item.id, show: true })
            }
            if (item.eventType === 'rechargeRewards') {
                this.onNavTo({ url: '/Affiliate' })
            }
            if (item.eventType === 'gameBettingRewards') {
                this.onNavTo({ url: '/Affiliate' })
            }
            if (item.eventType === 'pinduoduo') {
                this.$store.dispatch('user/setIsShowPinduoduoPopup', { id: item.id, show: true })
            }
            if (item.eventType === 'rankingList') {
                this.$store.dispatch('user/setIsShowWinRankListPopup', { id: item.id, show: true })
            }
            if (item.eventType === 'registerRewards') {
                const obj = { type: item.eventType }
                const params = encodeURIComponent(JSON.stringify(obj))
                const timestamp = new Date().getTime()
                this.onNavTo({ url: 'PromotionCustom', type: 'post', params: { timestamp, params } })
            }
            if (item.eventType === 'customerServiceTelegram') {
                this.onNavTo({ url: this.sysConfig.telegram, type: 'href' })
            }
            if (item.eventType === 'customerPending') {
                this.$store.dispatch('user/setIsShowPendingPopup', true)
            }
            if (item.eventType === 'totalRecharge') {
                this.$store.dispatch('user/setIsShowDepositTotalPopup', { id: item.id, show: true })
            }
            if (item.eventType === 'rechargeGift') {
                this.$store.dispatch('user/setIsShowDepositRepeatPopup', { id: item.id, show: true })
            }
            if (item.eventType === 'usdtRechargeGift') {
                this.$store.dispatch('user/setIsShowDepositRepeatUsdtPopup', { id: item.id, show: true })
            }
            if (item.eventType === 'loginRewards') {
                const obj = { type: item.eventType, id: item.id }
                const params = encodeURIComponent(JSON.stringify(obj))
                const timestamp = new Date().getTime()
                this.onNavTo({ url: 'PromotionCustom', type: 'post', params: { timestamp, params } })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.menus {
    position: fixed;
    z-index: 3;
    top: 0.7rem;
    right: 0.5rem;
    overflow-y: scroll;

    &.openNotification {
        right: 360px !important;
    }

    &.mobile {
        top: 22%;
        right: 0.3rem;

        // .fold_icon {
        //     opacity: 1;

        //     >img {
        //         width: 1rem;
        //         height: 1rem;
        //     }
        // }
    }
}

.menu_info {
    position: relative;
    width: 1.4rem;
    height: 1.4rem;

    .img_box {
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: relative;

        >img {
            width: 100%;
            height: 100%;

            &.txt {
                position: absolute;
                height: auto;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                object-fit: contain;
            }
        }
    }

    .iconfont {
        font-size: .22rem;
        position: absolute;
        right: 0;
        top: 0;
        color: #ffffff;
        cursor: pointer;
        font-weight: bold;
    }
    .matterNumber{
        width: .3rem;
        height: .3rem;
        position: absolute;
        background: red;
        color: #ffffff;
        display: flex;
		align-items: center;
		justify-content: center;
        top: 0;
		left: 0;
		font-size: .22rem;
        border-radius: 50%;
    }
}

.menus_box {
    display: flex;
    flex-direction: column;
    row-gap: 0.2rem;
    background: transparent;

    &.mobile {
        // row-gap: 0.3rem;

        .menu_info {
            width: 0.9rem;
            height: 0.9rem;

            .title {
                display: none;
            }
        }
    }
}

.fold_icon {
    opacity: 0;

    >img {
        width: 0.9rem;
        height: 0.9rem;
    }

    &.mobile {
        opacity: 1;
    }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background: transparent;
}

.ant-dropdown-menu {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}

.ant-dropdown-menu-item {
    padding: 0;
}
</style>
